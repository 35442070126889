<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <validation-observer
          ref="ForgotForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="onSubmit"
          >
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                  @keyup.enter="onSubmit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <b-card-text class="text-center mt-2">
                <span>¿Ya tienes cuenta? </span>
                <b-link>
                  <slot name="login" />
                </b-link>
              </b-card-text>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              type="submit"
              @keyup.enter="onSubmit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                type="grow"
              />
              <span class="align-middle">Recuperar contraseña</span>
            </b-button>

            <b-form-group>
              <b-card-text class="text-center mt-2">
                <span> Al crear una cuenta aceptas nuestros Terminos de Uso y </span>
                <b-link href="https://travelbus.com.co/politicas-de-privacidad/" target="_blank">
                  <span class="text-primary">Políticas</span>
                </b-link>
                de
                <b-link href="https://travelbus.com.co/politicas-de-privacidad/" target="_blank">
                  <span class="text-primary">Privacidad.</span>
                </b-link>
              </b-card-text>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BCol, BFormGroup, BFormInput,
  BLink, BRow, BCardText, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Forgot',
  components: {
    BButton,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BRow,
    BCardText,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      isLoading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    onSubmit() {
      this.$refs.ForgotForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          await this.$http.get('/csrf-cookie')
          this.$http.post('/auth/forgot', {
            email: this.userEmail,
          }).then(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'InboxIcon',
                variant: 'secondary',
                text: 'Te hemos enviado un email para restablecer tu contraseña!',
              },
            },
            {
              position: 'top-center',
            })
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
  },
}

</script>
