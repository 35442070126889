<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col
        cols="12"
        md="4"
        class="text-left p-3"
      >
        <b-link
          to="/"
          class="d-inline-block mx-auto mb-2"
        >
          <span class="img-fluid mb-4 mb-sm-0">
            <b-img
              :src="appLogoImage"
              style="filter: brightness(0) invert(1);"
              width="200"
              alt="logo"
            />
          </span>
        </b-link>
        <vue-social
          facebook="https://www.facebook.com/TravellBus"
          twitter="https://twitter.com/travelBusSAS"
          instagram="https://www.instagram.com/travelbussas/"
          linkedin="https://www.linkedin.com/company/travelbus/"
        />
        <section
          id="block-17"
          class="widget widget_block widget_media_image mb-3"
        >
          <a
            href="https://www.sic.gov.co/"
            target="_blank"
          ><figure class="wp-block-image size-full is-resized"><img
            decoding="async"
            loading="lazy"
            src="https://travelbus.com.co/wp-content/uploads/2021/07/Logo-superintendencia.png"
            alt="Logo superintendencia"
            class="wp-image-1853"
            width="222"
            height="45"
          ></figure></a>
        </section>
        <section
          id="block-18"
          class="widget widget_block"
        >
          <a
            href="https://www.supertransporte.gov.co/"
            target="_blank"
          ><figure class="wp-block-image size-full is-resized"><img
            decoding="async"
            loading="lazy"
            src="https://travelbus.com.co/wp-content/uploads/2021/07/Logo-supertransporte.png"
            alt="Logo supertransporte"
            class="wp-image-1854"
            width="72"
            height="47"
          ></figure></a>
        </section>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="p-3"
      >
        <h2 class="bd-text-purple-bright mb-1 text-secondary-color">
          <b>Acerca de travelBus</b>
        </h2>
        <ul class="list-unstyled ml-1 text-white item-link">
          <li>
            <b-link
              href="https://travelbus.com.co/sobre-nosotros/"
              target="_blank"
            >
              Sobre nosotros
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/contactanos/"
              target="_blank"
            >
              Contáctanos
            </b-link>
          </li>
        </ul>
      </b-col>

      <b-col
        cols="12"
        md="4"
        class="text-left p-3"
      >
        <h2 class="bd-text-purple-bright mb-1 text-secondary-color">
          <b>Info</b>
        </h2>
        <ul class="list-unstyled ml-1">
          <li>
            <b-link
              href="https://travelbus.com.co/terminos-y-condiciones/"
              target="_blank"
            >
              Términos y Condiciones
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/politicas-de-privacidad/"
              target="_blank"
            >
              Políticas de Privacidad
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/politicas-de-atencion-de-pqrs/"
              target="_blank"
            >
              Políticas de Atención de PQR’s
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/politica-de-cookies/"
              target="_blank"
            >
              Política de cookies
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/viajes-expresos/"
              target="_blank"
            >
              Viajes Expresos
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/recomendaciones/"
              target="_blank"
            >
              Recomendaciones
            </b-link>
          </li>
          <li>
            <b-link
              href="https://travelbus.com.co/necesitas-ayuda/"
              target="_blank"
            >
              Necesitas ayuda
            </b-link>
          </li>
          <li>
            <b-link
              href="https://www.sic.gov.co/"
              target="_blank"
            >
              www.sic.gov.co
            </b-link>
          </li>
        </ul>
      </b-col>
    </b-row>

    <hr>

    <p class="text-white">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <a
        href="https://travelbus.com.co/"
        target="_blank"
      >travelbus.com.co</a> Todos los derechos reservados
    </p>

  </b-container>
</template>

<script>
import {
  BLink, BImg, BContainer, BRow, BCol,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import AppSocial from '@core/layouts/components/AppSocial.vue'

export default {
  components: {
    BLink,
    BImg,
    BContainer,
    BRow,
    BCol,
    'vue-social': AppSocial,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style scoped>
.text-secondary-color {
  color: #ea01bd
}
.item-link li {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
