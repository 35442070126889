export default [
  {
    header: 'Inicio',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth',
    route: 'home',
    title: 'INICIO',
  },
  {
    title: 'Raise Support',
    href: 'https://pixinvent.ticksy.com/',
    icon: 'LifeBuoyIcon',
  },
]
